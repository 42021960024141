import { getRate } from '@vue/services/api/currency.api';
import { getContent, getMatrix } from '@vue/services/api/utility.api'
import { AvailabilityMatrix } from '@vue/common/availabilityMatrix'
import { showError, showWarning } from '@vue/services/toast.service'

import {
  SET_CALCULATOR_CONTENT,
  SET_RESULT,
  SET_STORE_SELECT,
  SET_STORE_MODAL
} from '@vue/common/constants/mutation.types'

import BigNumber from 'bignumber.js'

import { actionTypes } from '@vue/common/constants/store.types'
import { nextMultiple } from '@vue/services/currency.service'
import { TransactionTypes } from '@vue/common/constants/transactionTypes'
import { ProductTypes } from '@vue/common/constants/productTypes'

const DEFAULT_CURRENCY = "EUR";
const DEFAULT_INCREMENT = 1;
const DEFAULT_PRODUCT_TYPE = ProductTypes.CLICK_AND_COLLECT;

const state = {
  content: null,
  showStoreModal: false,
  matrix: null,
  transactionType: TransactionTypes.SELL,
  originalRequestQuantity: null,
  quantity: null,
  selectedProduct: null,
  selectedCurrency: null,
  quote: {
    currencyCode: DEFAULT_CURRENCY,
    increment: DEFAULT_INCREMENT,
    productId: DEFAULT_PRODUCT_TYPE,
    rate: null,
    price: null,
    hashCode: null
  }
}

const mutationTypes = {
  SET_TRANSACTION_TYPE: 'SET_TRANSACTION_TYPE',
  SET_ORIGINAL_REQUEST_QUANTITY: 'SET_ORIGINAL_REQUEST_QUANTITY',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_PRODUCT: 'SET_PRODUCT', 
  SET_CURRENCY: 'SET_CURRENCY',
  SET_QUOTE: 'SET_QUOTE'
};

const actions = {
  async init({ dispatch, commit, rootState }) {
    await dispatch('cart/init', null, { root: true });
    await dispatch('product/loadProducts', null, { root: true });

    const cart = rootState.cart.shoppingCart;

    // if there is an active cart we prefer its transactionType/product selection over the defaults.
    if (cart) {
      commit(mutationTypes.SET_TRANSACTION_TYPE, cart.product.transactionType); // bypass side effects
      await dispatch('selectProduct', cart.product.id);
      return;
    }

    // TODO: #inappropriate-init - we should make it easier to initialize properties from the server side
    //  there are random fields all over the codebase that could be consolidated into a paramater passed to this action.
    //  e.g. currency, qty, transactionType, product, and affiliateCode.

    const docType = window.doc_type;
    // TODO: window.isSellcurrency is badly named and weirdly a string, nuke it.
    const isBuyTransactionPage = docType === 'pageSellTravelMoney' || window.isSellcurrency === 'True';
    const isHomeDeliveryPage = docType === 'pageHomeDelivery';

    if(isBuyTransactionPage) {
      commit(mutationTypes.SET_TRANSACTION_TYPE, TransactionTypes.BUY) // bypass side effects
      await dispatch('selectProduct', ProductTypes.SELL_BACK)
      return
    }

    if(isHomeDeliveryPage) {
      commit(mutationTypes.SET_TRANSACTION_TYPE, TransactionTypes.SELL); // bypass side effects
      await dispatch('selectProduct', ProductTypes.HOME_DELIVERY);
      return;
    }

    commit(mutationTypes.SET_TRANSACTION_TYPE, TransactionTypes.SELL); // bypass side effects
    await dispatch('selectProduct', ProductTypes.CLICK_AND_COLLECT);
  },
  async getCalculatorContent({ commit }) {
    const { data } = await getContent()
    commit(SET_CALCULATOR_CONTENT, data)
  },
  getMatrix({ commit }) {
    getMatrix().then(({ data }) => {
      // TODO: Chucking this utility class instance into state is weird, refactor such that a getter is exposed.
      commit('setMatrix', new AvailabilityMatrix(data))
    })
  },
  [actionTypes.ToggleStoreModal]({ commit }, flag) {
    commit(SET_STORE_MODAL, flag)
  },
  [actionTypes.SetResult]({ commit }, flag) {
    commit(SET_RESULT, flag)
  },
  setTransactionType({ state, commit, dispatch }, transactionType) {
    if(state.transactionType === transactionType) {
      return;
    }

    commit(mutationTypes.SET_TRANSACTION_TYPE, transactionType);

    if(transactionType == TransactionTypes.BUY) {
      commit(mutationTypes.SET_ORIGINAL_REQUEST_QUANTITY, null);
    }

    const defaultProduct = transactionType === TransactionTypes.SELL
      ? ProductTypes.CLICK_AND_COLLECT
      : ProductTypes.SELL_BACK;

    dispatch('selectProduct', defaultProduct);
  },
  setOriginalRequestQuantity({ commit }, originalRequestQuantity) {
    commit(mutationTypes.SET_ORIGINAL_REQUEST_QUANTITY, originalRequestQuantity);
  },
  setQuantity({ commit }, quantity) {
    commit(mutationTypes.SET_QUANTITY, quantity);
  },
  selectProduct({ state, commit, dispatch, rootGetters }, productId) {
    if(state.selectedProduct?.id === productId) {
      return;
    }

    const product = rootGetters['product/findProduct'](productId);
    commit(mutationTypes.SET_PRODUCT,  product || null);
    dispatch('store/clearStores', null, { root: true }); // TODO: Nuke this rubbish!
    dispatch('updateQuote', { productId: productId } );
  },
  selectCurrency({ state, commit, dispatch }, currency) {
    commit(mutationTypes.SET_CURRENCY, currency)
    dispatch('store/clearStores', null, { root: true });
    dispatch('updateQuote', { currencyCode: currency.code } );
  },

  /**
   * updateQuote
   * Gets the currency rate applicable for the given parameters from the API and sets it in the store
   * @param {Object} { commit, rootState }
   * @param selectedProduct
   * @param selectedCurrency
   * @param defaultStore
   *
   * @returns {Object} quote - set in the store
   */
  updateQuote({ commit, state, rootState }, params) {
    const productId = params && params["productId"] || state.quote.productId;
    const currencyCode = params && params["currencyCode"] || state.quote.currencyCode;

    if (!productId || !currencyCode  || !rootState.store.defaultStore) return;

    const trxType = productId => productId === ProductTypes.SELL_BACK ? TransactionTypes.BUY : TransactionTypes.SELL;
    const currencyCodeChanging = currencyCode != state.quote.currencyCode;
    const transactionTypeChanging = trxType(productId) != trxType(state.quote.productId);

    const { store } = rootState

    // Set affiliate code
    const affiliateCode = sessionStorage.getItem('affiliateCode')
      ? sessionStorage.getItem('affiliateCode')
      : null
      
    // Commerce API call for currency rate
    getRate({
      storeId: store.store.id,
      productId: productId,
      currency: currencyCode,
      affiliateCode: affiliateCode,
    }).then(({ data }) => {
      data.hashCode = `${data.currencyCode}_${data.rate}`;

      if(data.increment != state.quote.increment) {
        const adjustedQuantity = nextMultiple(data.increment, state.originalRequestQuantity);
        const quantityChanged = adjustedQuantity != state.quantity;
        
        commit(mutationTypes.SET_QUANTITY, adjustedQuantity);

        if(quantityChanged && !currencyCodeChanging && !transactionTypeChanging) {
          // The choice of location has affected the quantity so warn the user about this
          getContent().then(result => { 
            showWarning(`${result.data.incrementNotification} ${state.selectedCurrency.symbol}${data.increment}`);
          });
        }
      }

      commit(mutationTypes.SET_QUOTE, { ...data, productId: productId, currencyCode: currencyCode })
    })
    .catch((err) => {
      if (err && err.response && err.response.data)
        showError(err.response.data.message);
    });
  }
}

const mutations = {
  [SET_CALCULATOR_CONTENT](state, content) {
    state.content = content
  },
  [SET_STORE_SELECT](state, flag) {
    state.showStore = flag
  },
  [SET_STORE_MODAL](state, flag) {
    state.showStoreModal = flag
  },
  setMatrix(state, availabilityMatrix) {
    state.matrix = availabilityMatrix
  },
  setCalculator(state, type) {
    state.type = type
  },
  [mutationTypes.SET_TRANSACTION_TYPE](state, transactionType) {
    state.transactionType = transactionType
  },
  [mutationTypes.SET_ORIGINAL_REQUEST_QUANTITY](state, originalRequestQuantity) {
    state.originalRequestQuantity = originalRequestQuantity
  },
  [mutationTypes.SET_QUANTITY](state, quantity) {
    state.quantity = quantity
  },
  [mutationTypes.SET_PRODUCT](state, product) {
    state.selectedProduct = product
  },
  [mutationTypes.SET_CURRENCY](state, currency) {
    state.selectedCurrency = currency
  },
  [mutationTypes.SET_QUOTE](state, quote) {
    state.quote = quote
  }
}

const getters = {
  isLoading: (state, getters, rootState) => {
    return state.content == null ||
      state.matrix == null ||
      rootState.store.defaultStore == null ||
      state.selectedCurrency == null
  },
  nextTier: (state, getters, rootState) => {
    if(!state.quote.rateTiers || state.quote.rateTiers.length < 1) {
      return null
    }

    const qty = state.quantity || 0;

    const sortedRates = (state.quote.rateTiers)
      .filter((i) => i.amount > qty)
      .sort((a, b) => a.amount - b.amount)

    return sortedRates[0] || null
  },
  tieredRate: (state, getters, rootState) => {
    const blankTieredRate = {
      amount: null,
      price: null,
      rate: null
    };

    if(!state.quote.rateTiers || state.quote.rateTiers.length < 1) {
      return blankTieredRate
    }

    const qty = state.quantity || 0;

    const sortedRates = state.quote.rateTiers
      .filter(tier => tier.amount <= qty)
      .sort((a, b) => b.amount - a.amount)

    return sortedRates[0] || blankTieredRate
  },
  rate: (state, getters, rootState) => {
    if (state.selectedCurrency === null || state.quote.rate === null)
      return 0

    return getters.tieredRate.rate
      ? getters.tieredRate.rate
      : state.quote.rate
  },
  // GBP -> FC
  rateForSourceQty: (state, getters, rootState) => (qty) => {
    const filteredThresholds = getters.rateTierThresholds
      .filter(threshold => qty >= threshold.threshold)

    const tiered = filteredThresholds[0] || null

    const fallback = state.quote;

    return  tiered?.rate || fallback?.rate || 0;
  },
  // FC -> GBP
  rateForTargetQty: (state, getters, rootState) => (rawQty, minDenom) => {
    const qty = nextMultiple(minDenom, Math.floor(rawQty))

    const filteredRates = (state.quote.rateTiers || [])
      .filter((i) => i.amount <= qty)
      .sort((a, b) => b.amount - a.amount)

    const tiered = filteredRates[0] || null

    const fallback = state.quote;

    return  tiered?.rate || fallback?.rate || 0;
  },

  rateTierThresholds: (state, getters, rootState) => {
    if(!state.quote.rateTiers || state.quote.rateTiers.length < 1)
      return []

    const thresholds = (state.quote.rateTiers)
      .map(x => {
        const threshold = BigNumber(x.amount).div(BigNumber(x.rate)).dp(2, BigNumber.ROUND_HALF_EVEN).toNumber()
        return {
          rate: x.rate,
          threshold
        }
      });   

    return thresholds.sort((a, b) => b.threshold - a.threshold)
  },
  price: (state, getters, rootState) => {
    if (state.quote.rate === null)
      return 0

    return getters.tieredRate.price
      ? getters.tieredRate.price
      : state.quote.price
  },
  // selling to customer
  isSellTransaction: (state) => state.transactionType === TransactionTypes.SELL,
  // buying from customer
  isBuyTransaction: (state) => state.transactionType === TransactionTypes.BUY,
}

const calculator = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default calculator;
