var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column store-select visible" }, [
    _c(
      "div",
      { staticClass: "label row" },
      [
        _vm.isBuyTransaction
          ? _c("span", [
              _vm._v(
                _vm._s(
                  _vm.content.storeFinderSellLabel ||
                    "3. Select a eurochange branch to collect your GBP from"
                )
              ),
            ])
          : _c("span", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.content.storeFinderLabel ||
                      "4. Select a eurochange branch to collect your currency from"
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _c("Tooltip", {
          attrs: { content: _vm.content.clickAndCollectTooltip },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.store || _vm.store.isMeta
      ? _c(
          "div",
          { staticClass: "find-store" },
          [
            _c("vue-google-autocomplete", {
              ref: "autocomplete",
              attrs: {
                id: "GooglePlaces",
                classname: "form-control",
                placeholder: "Enter your postcode, town or city",
                country: "gb",
                types: "",
              },
              on: { placechanged: _vm.getAddressData },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button--small button--secondary",
                on: {
                  click: function ($event) {
                    return _vm.getMyLocation()
                  },
                },
              },
              [_c("span", [_vm._v("Find a branch near me")])]
            ),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.store && !_vm.store.isMeta
      ? _c("div", { staticClass: "selected" }, [
          _c("div", { staticClass: "label row" }, [
            _c("span", [_vm._v("Selected Store")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [_vm._v("Reset")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "selected-modal-toggle",
              on: { click: _vm.enableToggleStoreModal },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.store.brandName) +
                  " " +
                  _vm._s(_vm.store.name) +
                  "\n    "
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Or browse with our "),
      _c("a", { attrs: { href: "/branches" } }, [
        _c("span", [_vm._v("branch finder")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }