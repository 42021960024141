import { getCurrencies } from '@vue/services/api/currency.api'
import { setDefaultCurrency } from '@vue/services/currency.service'
import { getParameterByName } from '@vue/services/calculator.service'

const state = {
  currencies: []
}

const DEFAULT_CURRENCY = "EUR";

const mutationTypes = {
  SET_CURRENCIES: 'SET_CURRENCIES'
};

const actions = {
  getCurrencies({ commit, dispatch }) {
    getCurrencies().then(({ data: currencyData }) => {
      commit(mutationTypes.SET_CURRENCIES, currencyData)
      let queryCurrency = getParameterByName('currency')

      let bc = localStorage.getItem("selcurr");
      if (bc !== null) {
        queryCurrency = bc;
        localStorage.removeItem("selcurr");
      }

      if(!queryCurrency) {
        commit("calculator/SET_CURRENCY", setDefaultCurrency(currencyData), { root: true } );
      } else {
        commit("calculator/SET_CURRENCY",
          currencyData.find(x => x.code.toUpperCase() === queryCurrency.toUpperCase()),
          { root: true } 
        );
      }
      dispatch("calculator/updateQuote", { currencyCode: queryCurrency || DEFAULT_CURRENCY }, { root: true } )
    })
  }
}

const mutations = {
  [mutationTypes.SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  }
}

const currency = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default currency
