<template>
  <div class="column store-select visible" >
    <div class="label row">
      <span v-if="isBuyTransaction">{{
        content.storeFinderSellLabel ||
        '3. Select a eurochange branch to collect your GBP from'
      }}</span>
      <span v-else>
        {{
          content.storeFinderLabel ||
          '4. Select a eurochange branch to collect your currency from'
        }}
      </span>
      <Tooltip :content="content.clickAndCollectTooltip" />
    </div>

    <div class="find-store" v-if="!store || store.isMeta">
      <vue-google-autocomplete
        id="GooglePlaces"
        classname="form-control"
        placeholder="Enter your postcode, town or city"
        country="gb"
        types=""
        v-on:placechanged="getAddressData"
        ref="autocomplete"
      />

      <button class="button button--small button--secondary" @click="getMyLocation()">
        <span>Find a branch near me</span>
      </button>

      <p>Or browse with our <a href="/branches"><span>branch finder</span></a></p>
    </div>
        
    <div class="selected" v-if="store && !store.isMeta">
      <div class="label row">
        <span>Selected Store</span>

        <button class="close" @click="reset()">Reset</button>
      </div>
      <button class="selected-modal-toggle" @click="enableToggleStoreModal" >
        {{ store.brandName }} {{ store.name }}
      </button>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  import { mapActions, mapState, mapGetters } from 'vuex'
  import { GEOCODING_URL, GEOCODING_KEY } from '@vue/common/constants/config'
  import VueGoogleAutocomplete from 'vue-google-autocomplete'
  import { getParameterByName } from '@vue/services/calculator.service'
  import Tooltip from '@vue/common/components/Tooltip'
  import { showError } from '@vue/services/toast.service'

  const LOCATION_PERMISSION_DENIED = 1;

  export default {
    name: 'StoreFinder',
    components: {
      Tooltip,
      VueGoogleAutocomplete,
    },
    data() {
      return {
        hideBranchFinder: false,
        disableToggleStore: false,
      }
    },
    computed: {
      ...mapState({
        content: (state) => state.calculator.content,
        store: (state) => state.store.store,
        selectedCurrency: (state) => state.calculator.selectedCurrency,
        quantity: (state) => state.calculator.quantity,
        selectedProduct: (state) => state.calculator.selectedProduct,
      }),
      ...mapGetters({
        isBuyTransaction: 'calculator/isBuyTransaction',
        isSellTransaction: 'calculator/isSellTransaction',
      }),
    },
    mounted() {
      const queryStore = getParameterByName('store_id')

      if (queryStore) {
        this.getStore(queryStore)
      }
      this.setStoreAddress();
    },
    methods: {
      ...mapActions({
        getNearby: 'store/getNearby',
        toggleStoreModal: 'calculator/toggleStoreModal',
        clearStores: 'store/clearStores',
        getStore: 'store/getStore',
        updateQuote: 'calculator/updateQuote'
      }),
      getAddressData(place) {
        if (place.latitude && place.longitude) {

          // For reasons unknown, the nearby branches endpoint requires an XTRIS order type passed.
          // To clean this up likely requires adding some v2 endpoints to the API.
          const xtrisOrderTypes = {
            SellBack: "B",
            HomeDelivery: "D",
            PayAndCollect: "CP"
          };

          const nearbyEndpointTransactionType = this.isSellTransaction
           ? xtrisOrderTypes.HomeDelivery
           : xtrisOrderTypes.SellBack;

          this.getNearby({
            Latitude: place.latitude,
            Longitude: place.longitude,
            amount: this.quantity,
            currency: this.selectedCurrency.code,
            transactionType: nearbyEndpointTransactionType
          })
          this.toggleStoreModal(true)
        } else {
          const params = {
            Key: encodeURIComponent(GEOCODING_KEY),
            Country: encodeURIComponent('GBR'),
            Location: encodeURIComponent(
              `${place.route}, ${place.locality}, ${place.administrative_area_level_1}, ${place.administrative_area_level_2}`
            ),
          }

          const queryString = Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')

          const url = `${GEOCODING_URL}?${queryString}`

          axios
            .post(url, null, { withCredentials: false })
            .then(({ data }) => {
              this.getNearby({
                ...data.Items[0],
                amount: this.quantity,
                currency: this.selectedCurrency.code,
              })
              this.toggleStoreModal(true)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      },

      async setStoreAddress() {
        // If we are on BranchPage setStore else skipping
        if (window.branchId) {
          await this.getStore(window.branchId);
          this.hideBranchFinder = true;
          this.disableToggleStore = true;
        }
      },
      enableToggleStoreModal() {
        if (!this.disableToggleStore) {
          this.toggleStoreModal(true);
        }
      },
      reset() {
        this.hideBranchFinder = false;
        this.disableToggleStore = false;
        this.clearStores();

        // Because increment would change if switching from an Express store
        this.updateQuote(); 

        this.$nextTick(() => {
          this.$refs.autocomplete.autocompleteText = '';  
          this.$refs.autocomplete.focus();          
        });
      },
      getMyLocation() {
        const success = pos => this.getAddressData({ latitude: pos.coords.latitude, longitude: pos.coords.longitude });

        const error = ({ code }) => {
            showError(`Sorry we couldn't get your location. ${code === LOCATION_PERMISSION_DENIED ? "Please grant access when a dialog appears." : ""}`);
        }

        navigator.geolocation.getCurrentPosition(success, error);
      }
    },

    watch: {
      selectedProduct(newValue) {
        if (this.hideBranchFinder && newValue.id != 1) {
          this.setStoreAddress();
        }
      },
    },
  }
</script>
